.mega-footer {
  background-color: #333;
  color: #fff;
  padding: 40px 20px;
  bottom: 0;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-column {
  flex: 1 1 200px;
  min-width: 150px;
}

.footer-column h3 {
  margin-bottom: 15px;
  color: #f9f7ed;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}

.footer-column ul li a {
  color: #f9f7ed;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-column ul li a:hover {
  color: #ffa726;
}

.footer-bottom {
  text-align: center;
  margin-top: 10px;
  border-top: 1px solid #555;
  padding-top: 20px;
  font-size: 0.9rem;
  color: #ffffff;
}

.footer-bottom p {
  color: white
}