/*************************************************
 *    1) Base Container + Toggling Classes       *
 *************************************************/
 .my-ui-container {
  display: grid;
  grid-template-areas: "sidebar main";
  width: 99%;
  height: 89.8vh;
  padding: 10px;
  overflow-x: hidden;
  background-color: #1e1e1e;
  color: #f1f1f1;
  gap: 10px;
}

.my-ui-container.sidebar-closed {
  grid-template-columns: 60px minmax(0,1fr);
}

.my-ui-container.sidebar-open {
  grid-template-columns: 300px minmax(0,1fr);
}

/*************************************************
 *                2) Sidebar                     *
 *************************************************/
 .sidebar-container {
  /* Let the sidebar fill its grid area’s vertical space */
  grid-area: sidebar;
  display: flex;
  flex-direction: column;

  /* If you want it to occupy the full height
     of the parent grid area, ensure you have
     a defined height on the parent. For example: */
  max-height: 90vh;

  background-color: #333;
  color: #f1f1f1;
  border-radius: 15px;
  transition: width 0.3s ease, min-width 0.3s ease;
  /* IMPORTANT: remove or limit overflow here, 
     because .sidebar-content will handle that. */
  overflow: hidden; 
}


/* .closed/.open for width transitions remain the same. */
.sidebar-container.closed {
  width: 60px;
  min-width: 60px;
}
.sidebar-container.open {
  width: 310px;
  min-width: 310px;
}

.sidebar-header {
  display: flex;
  align-items: center;
  background-color: #444;
  padding: 0.75rem;
  justify-content: space-between;
}

.sidebar-toggle {
  background: transparent;
  border: none;
  color: #f1f1f1;
  font-size: 1.3rem;
  cursor: pointer;
}

/* .sidebar-content: Let this area scroll */
.sidebar-content {
  /* Make this portion fill up the remaining space
     in the .sidebar-container vertically. */
  flex: 1;

  /* Enable scrolling here if content grows too tall */
  overflow-y: auto;  

  /* Your existing styling */
  padding: 10px;
  background-color: white;
}

/* If you want to explicitly remove overflow, use a .no-scroll class. */
.no-scroll {
  overflow: visible !important;
}

/* .sidebar-footer: pinned at bottom of the sidebar by virtue of 
   being below the flex:1 sibling */
   .sidebar-footer {
    /* This remains below the scrolling .sidebar-content
       because .sidebar-content has flex:1 and 
       .sidebar-footer is after it in the DOM. */
    background-color: #222;
    padding: 10px;
    border-top: 1px solid #555;
  }
  
  /* If you also want the conversation history list to 
     have its own scroll area, you can keep max-height, 
     e.g. 180px, and an overflow-y: auto. */
  .sidebar-footer .conversation-history {
    max-height: 180px;
    overflow-y: auto;
  }

.sidebar-footer .conversation-item {
  padding: 5px;
  font-size: 0.9rem;
  color: #ddd;
  border-bottom: 1px solid #444;
  cursor: pointer;
}

.sidebar-footer .conversation-item:hover {
  background-color: #555;
}

/*************************************************
 *          3) Main Content (1fr Area)          *
 *************************************************/
.main-content {
  grid-area: main;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 0;
}

/*************************************************
 *          4) Expert Matrix Container          *
 *************************************************/
.expert-matrix-container {
  background-color: transparent;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.expert-matrix-container h4 {
  margin-top: 0;
  margin-bottom: 10px;
  text-align: center;
}

.expert-grid {
  display: flex;
  gap: 5px;
  padding-top: 10px;
  justify-items: center;
  min-width: 500px;
  max-width: 97.5%;
  margin: 0 auto;
  flex-shrink: 1;
}

.expert-button {
  padding: 8px;
  background-color: #333;
  color: #f1f1f1;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  text-align: center;
  width: 120px;
  height: 50px;
}

.expert-button.selected {
  background-color: #4CAF50;
  color: white;
}

/*************************************************
 *        5) Conversation Container             *
 *************************************************/
.conversation-container {
  background-color: #1e1e1e;
  border: 1px solid #333;
  border-radius: 10px;
  overflow-y: auto;
  height: 78%;
  min-width: 96%;
  max-width: 98%;
  margin: 0 auto;
  flex: 0 1 auto;
}

.conversation-window {
  display: flex;
  flex-direction: column;
  width: 98%;
  overflow-y: auto;
  color: #fff;
  padding: 10px;
}

.message-bubble {
  display: inline-block;
  padding: 10px;
  border-radius: 10px;
  margin: 10px 0;
  max-width: 70%;
  word-wrap: break-word;
  background-color: #333;
}

.user-message {
  background-color: #4caf50;
  color: #fff;
  align-self: flex-end;
}

.ai-message {
  background-color: #18baeb;
  align-self: flex-start;
  white-space: pre-wrap;
  margin-left: 10px;
}

/*************************************************
 *            6) Entry Box Container            *
 *************************************************/
.entry-box-container {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.input-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 95%;
  max-width: 98%;
  margin: 0 auto; 
  padding: 10px;
  border-radius: 25px;
  position: relative;
  background-color: #333;
  flex-shrink: 1;
  margin-bottom: -15px;
}

.prompt-input {
  flex-grow: 1;
  padding: 10px;
  background-color: #222;
  border: none;
  border-radius: 25px;
  color: #f1f1f1;
  outline: none;
  resize: none;
  overflow: hidden;
  height: 20px;
  max-width: 87%;
}

.prompt-input:focus {
  min-height: 20px;
  max-height: 400px;
  overflow-y: auto;
}

.icon-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #bbb;
  margin-right: 20px;
  margin-left: 10px;
}

.icon-button:hover {
  color: #fff;
}

/*************************************************
 *     7) Attached Files, Console, Etc.         *
 *************************************************/
.attached-files-container {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
}

.attached-file-popup {
  display: flex;
  align-items: center;
  background-color: #444;
  padding: 5px 10px;
  border-radius: 5px;
  color: #f1f1f1;
  max-width: calc(20% - 10px);
}

.attached-file-popup img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 3px;
}

.attached-file-popup span {
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.remove-file-button {
  background: none;
  border: none;
  color: #ff6347;
  font-size: 1.2em;
  cursor: pointer;
}

/*************************************************
 *       8) Settings Panel Layout (New)         *
 *************************************************/
.settings-panel {
  display: flex;
  flex-direction: column;
  gap: 20px; /* space out each setting block */
}

.setting-item {
  background-color: #222;
  padding: 10px;
  border-radius: 8px;
}

.setting-item h4 {
  margin-top: 0;
  margin-bottom: 10px;
}

.expert-update-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.tts-button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.tts-enabled {
  background-color: #4caf50; 
  color: white;
}

.tts-disabled {
  background-color: #f44336; 
  color: white;
}

.volume-slider-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-top: 10px;
}

.volume-label,
.volume-level {
  font-size: 0.9em;
  width: 50px; 
}

.volume-slider {
  flex: 1;
}

.llm-dropdown {
  background-color: #444;
  border-radius: 5px;
  color: #f1f1f1;
  padding: 5px;
  border: none;
}

/*************************************************
 *       9) Edit Expert Popup Overlay           *
 *************************************************/
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.edit-popup {
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  color: #fff;
  text-align: center;
}
