/* Research Overview Page */
.research-overview-page {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  min-height: 100vh; /* Ensure full viewport height */
  padding-top: 40px;
  background-image: url(../../images/RetroLab.png); /* Background image */
  background-size: cover; /* Ensure the image covers the container */
  background-position: center;
  box-sizing: border-box; /* Include padding in layout calculations */
  position: relative; /* Needed for positioning the overlay */
}

.research-overview-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35); /* Black overlay with 50% transparency */
  z-index: 1; /* Place the overlay below the content */
  pointer-events: none; /* Allow interaction with elements above the overlay */
}

.research-overview-page .container {
  position: relative; /* Ensure content appears above the overlay */
  z-index: 2; /* Ensure containers are above the overlay */
  padding: 50px;
  margin: 0 auto;
  max-width: 1200px;
  text-align: left;
  border-radius: 10px;
  margin-bottom: 40px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  /* Removed default background color to prevent overriding section-specific styles */
}

/* Overview Section */
.research-overview-page .overview-section {
  background: transparent;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.research-overview-page .overview-section h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
  margin-left: 40%;
  color: white;
}

.research-overview-page .overview-section p {
  text-align: center;
  font-size: 1.2rem;
  color: white;
}

/* Quantum Consciousness Section */
.research-overview-page .container.quantum-consciousness-section {
  background: linear-gradient(135deg, #d1e8f0, #e0f7fa);
}

.quantum-consciousness-section h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.quantum-consciousness-section p {
  font-size: 1.2rem;
}

/* Coherence and Decoherence Section */
.research-overview-page .container.coherence-decoherence-section {
  background: linear-gradient(135deg, #cfe8d1, #eaf7e0);
}

.coherence-decoherence-section h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.coherence-decoherence-section p {
  font-size: 1.2rem;
}

/* E-Coin Section */
.research-overview-page .container.e-coin-section {
  background: linear-gradient(135deg, #e0d4f3, #f3e6fc);
}

.e-coin-section h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.e-coin-section h2 {
  font-size: 2rem;
  margin-top: 30px;
  margin-bottom: 20px;
  color:#333;
}

.e-coin-section ul {
  list-style: disc;
  padding-left: 20px;
  margin: 20px 0;
}

.e-coin-section li {
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.e-coin-section li strong {
  font-weight: bold;
}

.e-coin-section p {
  font-size: 1.2rem;
  margin-top: 20px;
}

/* Quantum Computing Section */
.research-overview-page .container.quantum-computing-section {
  background: linear-gradient(135deg, #f4d9c1, #fdece0);
}

.quantum-computing-section h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.quantum-computing-section p {
  font-size: 1.2rem;
}

/* Utility Styles */
.research-overview-page h1, h2 {
  color: #222;
}

.research-overview-page p {
  color: #555;
}

.research-overview-page ul {
  color: #333;
}

.research-overview-page .container h1, .research-overview-page .container h2 {
  text-align: left;
}

.research-overview-page h3 {
  position: relative; /* Needed to ensure it appears above the overlay */
  z-index: 2; /* Ensures the container content is above the overlay */
  max-width: 70%;
  margin-left: 13%;
  padding: 50px;
  text-align: center;
  color: white;
}

/* Mega Footer */
.research-overview-page .mega-footer {
  position: relative; /* Ensure stacking context for the footer */
  z-index: 3; /* Place the footer above the overlay */
  margin-top: auto; /* Push footer to the bottom of the page */
  flex-shrink: 0; /* Prevent footer from shrinking */
  width: 100%; /* Full width of the page */
  background-color: #333; /* Footer background */
  color: #fff; /* Footer text color */
  text-align: center;
  padding: 20px 0;
}
