.navbar {
  position: sticky;
  top: 0;
  background-color: wheat;
  transition: background-color 0.3s, box-shadow 0.3s;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo img.logo-button {
  width: 140px; /* Adjust width as needed */
  height: 70px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.navbar-list {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-item {
  position: relative;
  margin: 0 20px;
}

.navbar ul {
  align-items: center;
  padding: 0px;
  font-size: 1.3rem;
}

.navbar-item button {
  background: none;
  border: none;
  color: rgb(0, 0, 0);
  font-size: 1.3rem;
  margin-right: -10px;
  cursor: pointer;
}

/* Reset link styles in navbar */
.navbar-item a {
  text-decoration: none; /* Remove underline */
  color: rgb(0, 0, 0); /* Default color for links */
  font-size: 1.3rem;
  margin-right: -10px;
  cursor: pointer;
  transition: color 0.3s ease;
}

/* Link states */
.navbar-item a:visited,
.navbar-item a:link {
  text-decoration: none; /* Ensure no underline for visited links */
  color: rgb(0, 0, 0); /* Match default link color */
}

.navbar-item a:hover {
  color: #555; /* Add hover effect for links */
}

.navbar-item a:active {
  color: #333; /* Add active state styling */
}

.navbar-item .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  border-radius: 25px;
  color: black;
  list-style: none;
  padding: 10px 0px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: none;
  z-index: 1000;
}

.navbar-item:hover .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  padding: 5px 0px;
}

.dropdown-menu li a {
  text-decoration: none;
  color: transparent;
  display: block;
}

.dropdown-menu li a:hover {
  background-color: #000000;
}

.navbar-item .active {
  font-weight: bold;
  color: #000000;
}

/* Contact Section */
.contact-section {
  padding: 50px;
  text-align: center;
  background-color: wheat;
}

.contact-section form .form-group {
  margin: 15px 0;
}

.contact-section form input,
.contact-section form textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.contact-section form button {
  padding: 10px 20px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.contact-section form button:hover {
  background-color: #575757;
}