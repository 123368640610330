/* MCX Roadmap Background */
.roadmap-background-mcx {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../../images/RetroRoad2.png) no-repeat center center fixed;
  background-size: cover;
  z-index: -1;
  pointer-events: none;
}

/* MCX Roadmap Content Wrapper */
.roadmap-content-wrapper-mcx {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 80px;
  position: relative;
  z-index: 1;
}

/* MCX Glassmorphic Container */
.roadmap-glassmorphic-container-mcx {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin: 20px auto;
  max-width: 900px;
  text-align: left;
  color: white;
}

/* Header and Text Styling */
.roadmap-content-mcx h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  text-align: center;
  color: white;
}

.roadmap-content-mcx h2 {
  font-size: 1.8em;
  margin-top: 20px;
  color: white;
}

/* List Styling */
.roadmap-content-mcx ul {
  margin-left: 20px;
  list-style-type: disc;
  padding: 0;
}

.roadmap-content-mcx li {
  font-size: 1.2em;
  line-height: 1.6;
  color: #fff;
  margin-bottom: 10px;
}

/* Footer Styling */
.roadmap-content-wrapper-mcx .mega-footer {
  margin-top: auto;
  width: 100%;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 20px 0;
  z-index: 2;
}

.mega-footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mega-footer ul li {
  margin: 5px 0;
}

.mega-footer a {
  color: #ffa726;
  text-decoration: none;
}

.mega-footer a:hover {
  text-decoration: underline;
}
