/* General Reset */
.homepage body, .homepage html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  height: 100%;
  overflow-x: hidden;
}

.homepage {
  display: flex;
  flex-direction: column; /* Stack sections vertically */
}

/* Navbar styles */
.homepage .navbar {
  position: sticky;
  top: 0;
  background-color: wheat;
  padding: 10px 20px;
  z-index: 1000;
}

.homepage .navbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
}

.homepage .navbar ul li {
  display: inline;
  margin-right: 20px;
}

.homepage .navbar ul li a {
  text-decoration: none;
  color: rgb(0, 0, 0);
  padding: 10px 10px;
}

.homepage .navbar ul li a:hover {
  background-color: transparent;
  border-radius: 25px;
}

/* Parallax Effect for Sections */
.homepage .parallax {
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.8);
}

/* Intro Section */
.homepage .intro-section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('./components/images/RetroSunrise.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  text-align: center;
  color: white;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.8);
}

.homepage .intro-section .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.homepage .hero-text {
  position: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.homepage .hero-text h1 {
  max-width: 80%;
  font-size: 6rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #1ABC9C;
}

.homepage .hero-text p {
  font-size: 1.5rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  color: #20A583;
}

.homepage .cta-button {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
  background: #1ABC9C;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  padding: 12px 24px;
  border-radius: 25px;
  border: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.homepage .cta-button:hover {
  background: linear-gradient(90deg, #16A085, #1ABC9C);
  transform: scale(1.05);
}

.homepage .cta-button.secondary {
  background: #1ABC9C;
}

/* About Section */
.homepage .about-section {
  display: flex;
  flex-direction: row;
  background-color: wheat;
  justify-content: space-between;
  align-items: stretch;
  max-height: 100vh;
  padding: 50px;
  margin-bottom: -140px;
}

.homepage .about-image {
  position: relative;
  flex: 6;
  background-image: url('./components/images/RetroDay.png');
  max-height: 84vh;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  overflow: hidden;
}

.homepage .about-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
  z-index: 1;
}

.homepage .about-text {
  flex: 4;
  max-height: 79.72vh;
  padding: 20px;
  background: #20A583;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.homepage .about-text h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: white;
}

.homepage .about-text p {
  font-size: 1.2rem;
  color: white;
}

/* Features Section */
.homepage .features-section {
  text-align: center;
  padding: 50px;
  background: rgba(0, 0, 0, 0.35);
  background-image: url('./components/images/RetroSunset.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.homepage .features-section h2 {
  color: white;
}

.homepage .features-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 60px;
  column-gap: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.homepage .feature-card {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
}

.homepage .feature-card h3 {
  margin-bottom: 10px;
}

.homepage .feature-card p {
  margin-bottom: 15px;
}

.homepage .feature-card a {
  align-self: flex-start;
  text-decoration: none;
  padding: 10px 15px;
  background: #1ABC9C;
  color: #ffffff;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.homepage .feature-card a:hover {
  background: linear-gradient(90deg, #16A085, #1ABC9C);
  transform: scale(1.05);
}

/* Footer Styling */
.homepage .mega-footer {
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
  width: 100%;
}
