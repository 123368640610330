.hlb-overview-page {
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
  padding: 0;
  margin: 0;
  position: relative; /* Needed for positioning the overlay */
  min-height: 100vh; /* Ensure the page height includes the footer */
  display: flex;
  flex-direction: column; /* Allow footer to sit at the bottom */
}

.hlb-overview-page::before {
  content: '';
  position: absolute; /* Changed to absolute */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Cover the entire page height */
  background-image: url(../../images/RetroHLB.png); /* Background image */
  background-size: cover; /* Ensure the background image covers the entire area */
  background-position: center;
  z-index: 1; /* Ensures the overlay sits below the content */
}

.hlb-overview-page::after {
  content: '';
  position: absolute; /* Changed to absolute */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Cover the entire page height */
  background: rgba(0, 0, 0, 0.35); /* Black overlay with 50% transparency */
  z-index: 2; /* Ensures the overlay sits above the background but below content */
}

.hlb-overview-page .container {
  position: relative; /* Ensure content appears above the overlay */
  z-index: 3;
  padding: 50px;
  margin: 0 auto;
  max-width: 1200px;
  text-align: left;
  border-radius: 10px;
  background: #fff; /* Default background color for sections */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for separation */
  margin-bottom: 20px;
}


/* Section-Specific Styles */
.hlb-overview-page .overview-section {
  margin-top: 40px;
  background: linear-gradient(135deg, #f4e8c1, #fef8e0);
}

.hlb-overview-page .about-section {
  background: linear-gradient(135deg, #d1e8f0, #e0f7fa);
  margin-top: 20px;
  display: block;
}

.hlb-overview-page .goals-section {
  background: linear-gradient(135deg, #e0d4f3, #f3e6fc);
}

.hlb-overview-page .leadership-section {
  background: linear-gradient(135deg, #cfe8d1, #eaf7e0);
}

.hlb-overview-page .website-announcement-section {
  background: linear-gradient(135deg, #f4d9c1, #fdece0);
}

/* Typography */
.hlb-overview-page h1 {
  font-size: 2.5rem;
  color: #222;
  margin-bottom: 20px;
}

.hlb-overview-page h2 {
  font-size: 2rem;
  color: #444;
  margin-top: 30px;
  margin-bottom: 20px;
}

.hlb-overview-page p {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 20px;
}

.hlb-overview-page .news-date {
  font-style: italic;
  color: #666;
  margin-bottom: 20px;
}

/* List Styles */
.hlb-overview-page ul {
  list-style-type: disc;
  margin-left: 40px;
  margin-top: 20px;
}

.hlb-overview-page li {
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.hlb-overview-page li strong {
  font-weight: bold;
  color: #222;
}

/* Utility Styles */
.hlb-overview-page .container h1, .hlb-overview-page .container h2 {
  text-align: left;
}

.hlb-overview-page .container p:last-child {
  margin-bottom: 0;
}

.hlb-overview-page .mega-footer {
  position: relative; /* Ensure the footer is properly positioned */
  z-index: 3; /* Higher than the pseudo-elements */
  flex-shrink: 0; /* Prevent footer from shrinking */
  width: 100%; /* Full width of the page */
  background-color: #333; /* Footer background */
  color: #fff; /* Footer text color */
  text-align: center;
  padding: 20px 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hlb-overview-page .container {
    padding: 30px;
  }

  .hlb-overview-page h1 {
    font-size: 2rem;
  }

  .hlb-overview-page h2 {
    font-size: 1.5rem;
  }

  .hlb-overview-page p, .hlb-overview-page li {
    font-size: 1rem;
  }
}

.hlb-overview-page .mega-footer li {
  list-style-type: none;
}
